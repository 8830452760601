import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

const TOKEN_KEY = 'USER_AUTH';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private tenantToken = '';
  private userToken = '';

  constructor() {
    this.loadToken();
  }

  async loadToken() {
    const ret = await Storage.get({key: TOKEN_KEY});
    if (ret && ret.value) {
      const auth = JSON.parse(ret.value);
      if (auth && auth.tenantToken && auth.userToken) {
        this.tenantToken = auth.tenantToken;
        this.userToken = auth.userToken;
        this.isAuthenticated.next(true);
      } else {
        this.isAuthenticated.next(false);
      }
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(tenantToken, userToken): Promise<any> {
    return new Promise<any>(resolve => {
      this.tenantToken = tenantToken;
      this.userToken = userToken;
      this.isAuthenticated.next(true);
      Storage.set({
        key: TOKEN_KEY, 
        value: JSON.stringify({
          tenantToken,
          userToken
        })
      });
      resolve(true);
    });
  }

  public logout() {
    this.tenantToken = '';
    this.userToken = '';
    this.isAuthenticated.next(false);
    Storage.remove({key: TOKEN_KEY});
  }

  public getTenantToken() {
    return this.tenantToken;
  }
  public getUserToken() {
    return this.userToken;
  }

}
