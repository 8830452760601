import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {}

  apiUrl() {
    return 'https://kb1e0nu2sa.execute-api.us-east-1.amazonaws.com/api/'
    // return 'https://app.skuvault.com/api/';
  }

  login(Email, Password): Observable<any> {
    const loginRequest = {Email, Password};
    return this.http
      .post(this.apiUrl() + 'getTokens', loginRequest);
  }

  getLocations() {
    const body = {};
    return this.http
      .post(this.apiUrl() + 'inventory/getLocations', body);
  }

  getInventory(PageNumber, PageSize) {
    const body = {
      IsReturnByCodes: false,
      PageNumber,
      PageSize
    };
    return this.http
      .post(this.apiUrl() + 'inventory/getInventoryByLocation', body, {observe: 'response'});
  }

  getWarehouses() {
    const body = {};
    return this.http
      .post(this.apiUrl() + 'inventory/getWarehouses', body);
  }

  addItemBulk(Items) {
    const body = {Items};
    return this.http
      .post(this.apiUrl() + 'inventory/addItemBulk', body, {observe: 'response'});
  }

  removeItemBulk(Items) {
    const body = {Items};
    return this.http
      .post(this.apiUrl() + 'inventory/removeItemBulk', body, {observe: 'response'});
  }

}
