import {Injectable} from '@angular/core';
import {CanLoad, Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {Observable} from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
  constructor(private authenticationService: AuthenticationService, private router: Router) {
  }

  canLoad(): Observable<boolean> {
    return this.authenticationService.isAuthenticated.pipe(
      filter(val => val !== null), // Filter out initial Behaviour subject value
      take(1), // Otherwise the Observable doesn't complete!
      map(isAuthenticated => {
        if (isAuthenticated) {
          // Directly open inside area
          this.router.navigateByUrl('/tabs', {replaceUrl: true});
        } else {
          // Simply allow access to the login
          return true;
        }
      })
    );
  }
}
