import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, from, Observable, throwError} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {ApiService} from '../services/api.service';
import {Router} from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private authenticationService: AuthenticationService, private apiService: ApiService,
              private router: Router) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if ((request.url.includes('getTokens'))) {
      return next.handle(request)
        .pipe(catchError(this.handleError.bind(this)));
    }
    /*if (this.isRefreshInProgress) {
      // console.log('waiting');
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap(() => {
          console.log('done waiting');
          return next.handle(this.addAuthenticationToken(request))
            .pipe(catchError(this.handleError.bind(this)));
        })
      );
    } else {
      if (this.authenticationService.isTokenGettingOld()) {
        // console.log('refreshing token');
        this.isRefreshInProgress = true;
        this.refreshTokenSubject.next(null);
        return this.authenticationService.refreshAccessToken().pipe(
          switchMap((response) => {
            // console.log('token received');
            return from(this.authenticationService.login(response.access_token, response.refresh_token, response.expires_in,
              this.authenticationService.getRealmId()))
              .pipe(
                switchMap(() => {
                  // console.log('done refreshing');
                  this.isRefreshInProgress = false;
                  this.refreshTokenSubject.next(this.authenticationService.getToken());
                  return next.handle(this.addAuthenticationToken(request))
                    .pipe(catchError(this.handleError.bind(this)));
                })
              );
          }) 
        );
      } else {
        return next.handle(this.addAuthenticationToken(request))
          .pipe(catchError(this.handleError.bind(this)));
      }
    }*/
    return next.handle(this.addAuthenticationToken(request))
      .pipe(catchError(this.handleError.bind(this)));
  }

  addAuthenticationToken(request) {
    return request.clone({
      body: {
        ...request.body,
        ...{TenantToken: this.authenticationService.getTenantToken(), UserToken: this.authenticationService.getUserToken() }}
    });
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.onUnauthorized();
      return throwError('Unauthorized!');
    } else {
      return throwError(error);
    }
  }

  private async onUnauthorized() {
    await this.authenticationService.logout();
    this.router.navigateByUrl('login', { replaceUrl: true });
  }

}
